import React, { useCallback, useEffect, useRef, useState } from 'react'
import { appOPDIcon, headerLogo } from '../../../images'
import { FiSearch } from 'react-icons/fi';
import { hospitalIcon, no_medicine_found } from '../../Medicine/Images';
import { FaAngleRight } from 'react-icons/fa';
import { debounce } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { fetchHospitalList } from '../../../Redux/actions/GetApiCall';
import MediLoader from '../../Medicine/animation/MediLoader';
import { useNavigate } from 'react-router';
import NoDoctorFoundAnimation from '../../Medicine/animation/NoDoctorFoundAnimation';

const SearchHospital = () => {
    const [inputValue, setInputValue] = useState('');
    const { data: SearchData, error: SearchError, loader: SearchLoader } = useSelector(state => state?.hospital_list);
    const dispatch = useDispatch()
    const [searchResult, setSearchResult] = useState([]);
    const navigate = useNavigate()
    const debounceFetchMedicine = useCallback(
        debounce((value) => {
            if (value.trim() !== '') {
                dispatch(fetchHospitalList("", value));
            } else {
                setSearchResult([]);
            }
        }, 1000),
        [dispatch]
    );

    const inputRef = useRef(null);

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    }, []);

    useEffect(() => {
        if (inputValue !== "") {
            debounceFetchMedicine(inputValue);
        }
        return () => {
            debounceFetchMedicine.cancel();
        };
    }, [inputValue, debounceFetchMedicine]);

    useEffect(() => {
        setSearchResult(SearchData);
        if (inputValue === "") {
            setSearchResult([]);
        }
    }, [SearchData, inputValue]);

    const handleInputChange = (e) => {
        const value = e.target.value;
        setInputValue(value);
        if (value.trim() === '') {
            setSearchResult([]);
        }
    };

    const height = "75%";

    const getHighlightedText = (text, highlight) => {
        const parts = text.split(new RegExp(`(${highlight})`, 'gi'));
        return parts.map((part, index) =>
            part.toLowerCase() === highlight.toLowerCase() ? <strong key={index} style={{ color: 'black' }}>{part}</strong> : part
        );
    };

    const handleSearch = (Data) => {
        setInputValue('');
        console.log(Data);

        if (Data?.id) {
            const hostData = {
                id: Data?.id
            }
            navigate('/hospital-details', { state: hostData });
        } else {
            // Navigate to doctor-details if hospital_id does not exist
            const data = {
                doctor_id: Data?.doctor_id,
                departments: Data?.departments,
                hospitalID: Data?.hospital_id,
            };
            navigate('/doctor-detail', { state: data });
        }
    };

    const handleFormRepo=()=>{
        navigate("/opd-form")
    }

    return (
        <div className='home-review-banner'>
            <div className="home-app-header sticky-top">
                <div className="header-logo">
                    <img src={headerLogo} width="100%" alt="header-logo" />
                    <div className="user-name-text-repo">
                        <p style={{ fontSize: '16px', marginLeft: '10px' }}>Search Hospital</p>
                    </div>
                </div>
            </div>

            <div className="search-bar">
                <input
                    type="text"
                    placeholder="Search hospitals..."
                    onChange={(e) => handleInputChange(e)}
                    ref={inputRef}
                    onKeyPress={(e) => {
                        const regex = /^[a-zA-Z\s]*$/;  // Regular expression to allow letters and spaces only
                        if (!regex.test(e.key)) {
                            e.preventDefault(); // Prevent non-letter characters
                        }
                    }}
                />
                <FiSearch />
            </div>

            {
                SearchLoader ? (
                    <MediLoader height={height} />
                ) : (
                    <div className="data-repo-cover-medi-search">
                        {inputValue.trim() !== '' && (searchResult?.data?.length > 0 || searchResult?.doctor?.length > 0) ? (
                            // Combine and display search results
                            [...(searchResult?.data || []), ...(searchResult?.doctor || [])]
                                .filter(item => item?.doctor_name || item?.name) // Filter out invalid items
                                .map((item, index) => (
                                    <div className="search-result-data-box-cover" key={index} onClick={() => handleSearch(item)}>
                                        <div className="search-data-box-cont">
                                            <img
                                                src={item?.doctor_name ? appOPDIcon : hospitalIcon} // Different icons for doctor and hospital
                                                alt="product-icon"
                                            />
                                            <p className='ellipsis-text'>
                                                {getHighlightedText(item?.doctor_name || item?.name || '', inputValue)}
                                            </p>
                                        </div>
                                        <div className="search-left-arrow-box">
                                            <FaAngleRight />
                                        </div>
                                    </div>
                                ))
                        ) : inputValue.trim() !== '' && (searchResult?.data?.length === 0 && searchResult?.doctor?.length === 0) ? (
                            // Else if: Display "If You Do not found" message
                            <p className='no-medicine-found-bankai' style={{ height: '65vh' }}>
                                <span style={{ fontSize: '15px' }}>OOPS</span>
                                <span className='repo-bank-ichigo' style={{ fontSize: '15px' }}>No such request found</span>
                                <span className='click-bankai-span' style={{ fontSize: '16px' }} onClick={handleFormRepo}>Please click below to schedule the appointment</span>
                            </p>
                        ) : (
                            // Else: Display only the image
                            <p className='no-medicine-found-bankai' style={{ position: 'relative' }}>
                                <NoDoctorFoundAnimation/>
                            </p>
                        )}
                    </div>
                )
            }

        </div>
    )
}

export default SearchHospital