import React, { useState } from 'react'
import { IoLocationSharp } from "react-icons/io5";
import { appOPDIcon } from '../../images';
import { online_consultation, person_consultation } from '../Medicine/Images';
import { useNavigate } from 'react-router-dom';
import { Button, Modal } from 'react-bootstrap';

const DoctorCard = ({ doctor, hospitalId, hospitalAddress }) => {
    const navigate = useNavigate();
    const handleDetails = (Data) => {
        console.log(Data)
        const DataRepo = {
            doctor_id: Data?.doctor_id,
            departments: Data?.departments,
            hospitalID: Data?.hospitalDetails?.[0]?.hospital_id || hospitalId,
        }
        navigate("/doctor-detail", { state: DataRepo })
    }

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalData, setModalData] = useState(null);

    const handleOpenModal = (departments) => {
        setModalData(departments);
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
        setModalData(null);
    };


    return (
        <div className="doctor-card">
            <div className="doctor-info" onClick={() => handleDetails(doctor)}>
                <div className="doctor-rpo-cover">
                    <div className="doctor-repo-clover-box">
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <h3 className="doctor-name">{doctor?.name || doctor?.doctor_name}</h3>
                            <span className="admin-tag"
                                onClick={(event) => {
                                    event.stopPropagation(); // Prevents triggering handleDetails
                                    handleOpenModal(doctor?.departments);
                                }}>
                                {doctor?.departments?.[0]?.department_name}
                                {doctor?.departments?.length > 1 && (
                                    <span
                                        className='depart-count'
                                    >
                                        +{doctor?.departments?.length - 1}
                                    </span>
                                )}
                            </span>
                        </div>
                    </div>

                    <div className="doctor-repo-cover">
                        <div className="doctor-image">
                            <img src={appOPDIcon} alt="" />
                        </div>
                        <div className="doctor-header">
                            <p className="doc-qualification" style={{ textAlign: 'start' }}>{doctor.education}</p>
                            <p className="doctor-category"><IoLocationSharp />{hospitalAddress ?? doctor?.hospitalDetails?.[0]?.hospital_address ?? doctor?.location}</p>
                        </div>
                    </div>

                </div>
                <div className="doctor-buttons-repo">
                    {(doctor?.opd_price != null || doctor?.online_price != null) && (
                        <div className="repo-clover-price-data">
                            <div className="online">
                                <img src={online_consultation} alt="online-consultation" />
                                Online
                            </div>
                            <div className="price-tag-doc">
                                <p>₹ {doctor?.online_price || doctor?.opd_price}</p>
                            </div>
                        </div>
                    )}
                    {(doctor?.price != null || doctor?.in_person_price != null) && (
                        <div className="repo-clover-price-data">
                            <div className="online">
                                <img src={person_consultation} alt="in-person-consultation" />
                                In-person
                            </div>
                            <div className="price-tag-doc">
                                <p>₹ {doctor?.price || doctor?.in_person_price}</p>
                            </div>
                        </div>
                    )}
                </div>


                <div className="book-apointment-btn">
                    <button>
                        BOOK YOUR APPOINTMENT
                    </button>
                </div>

            </div>
            {isModalOpen && (
                <Modal show={isModalOpen} onHide={handleCloseModal} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Departments</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <ul>
                            {modalData?.map((department, index) => (
                                <li key={index}>{department.department_name}</li>
                            ))}
                        </ul>
                    </Modal.Body>
                </Modal>
            )}
        </div>
    )
}

export default DoctorCard