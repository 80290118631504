import React, { useState } from 'react';
import { Modal, Button, Spinner } from 'react-bootstrap'; // Add Spinner for loader
import DatePicker from 'react-datepicker';
import { format } from 'date-fns';
import '../style/Telestyle.css';
import 'react-datepicker/dist/react-datepicker.css';
import { useDispatch, useSelector } from 'react-redux';
import { dentalTimeSlot, doctorTimeSlot, getDepartmentTimeSlot } from '../../../../../Redux/actions/PostApiCall';

const DentalReorder = ({ show, handleClose, doctorId, ClinicId, setSelectedReason, selectedReason, selectedDate, setSelectedDate, handleReorder }) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const DoctorTimeSlot = useSelector(state => state.dental_time_slot);

    const handleDateChange = (date) => {
        const formData = new FormData();
        setSelectedDate(date);
        const formattedDate = format(date, 'dd-MM-yyyy');

        if (formattedDate && ClinicId && doctorId) {
            const RepoData = {
                doctor_id: doctorId,
                clinic_id: ClinicId,
                date: formattedDate
            }
            dispatch(dentalTimeSlot(RepoData))
                .finally(() => setLoading(false)); // End loading after the API call completes
        }
    };

    const handleReasonChange = (event) => {
        setSelectedReason(event.target.value);
    };

    const minDate = new Date(); // Current date
    const maxDate = new Date(); // 3 months from now
    maxDate.setMonth(maxDate.getMonth() + 3);

    return (
        <Modal show={show} onHide={handleClose} centered>
            <Modal.Header>
                <Modal.Title>
                    <p className='Reduc-late'>RESCHEDULE APPOINTMENT</p>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="form-group-depo">
                    <label htmlFor="cancelDate">Select Date:</label>
                    <DatePicker
                        selected={selectedDate}
                        onChange={handleDateChange}
                        className="form-control"
                        id="cancelDate"
                        dateFormat="dd/MM/yyyy"
                        // minDate={today}
                        placeholderText="Select Date"
                        minDate={minDate}
                        maxDate={maxDate}
                    />
                </div>

                <div className="form-group-depo">
                    <label htmlFor="cancelReason">Select Time:</label>
                    <select
                        id="cancelReason"
                        value={selectedReason}
                        onChange={handleReasonChange}
                        className="form-control"
                        disabled={loading || !DoctorTimeSlot?.data} // Disable if loading or no data
                    >
                        <option value="" disabled>
                            {loading ? 'Loading...' : 'Select Time'}
                        </option>
                        {loading ? ( // Show loader when API is pending
                            <option value="" disabled>
                                <Spinner animation="border" size="sm" /> Loading...
                            </option>
                        ) : DoctorTimeSlot?.data?.available_times?.length ? (
                            DoctorTimeSlot?.data?.available_times
                                ?.filter((time) => time?.available) // Filter for available times
                                ?.map((time) => (
                                    <option key={time?.time} value={time?.time}>
                                        {time?.time}
                                    </option>
                                ))
                        ) : (
                            <option value="" disabled>No time slots available</option>
                        )}
                    </select>
                </div>

                <div className="confirmation-buttons-repo-box">
                    <Button className='yes-modal-data' disabled={!selectedReason} onClick={handleReorder}>Yes</Button>
                    <Button className='no-modal-data' onClick={handleClose}>No</Button>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default DentalReorder;
