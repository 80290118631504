import React, { useEffect } from 'react'
import { headerLogo } from '../../../images'
import '../Medicine_Style/eye.css'
import { DentalBanner, DentalBanner2, DentalBanner3, DentalBanner4, DentalBanner5, DentalBannerImage1, DentalBannerImage2, EyeBanner, EyeImage, EyeImage2, EyeImage3, EyeOtherBanner } from '../Images'
import { useNavigate } from 'react-router'
import useLocationState from '../hooks/useLocationState'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay } from 'swiper/modules'
import { useDispatch, useSelector } from 'react-redux'
import { fetchDentalPartners, fetchEyePartners } from '../../../Redux/actions/GetApiCall'
import MediLoader from '../animation/MediLoader'


const Eye = () => {
    const naviagte = useNavigate();
    const PackData = useLocationState();
    const dispatch = useDispatch();
    const { data: DenatlData, loader: DentalLoader } = useSelector(state => state.Eye_partners)
    console.log(DenatlData)

    useEffect(() => {
        dispatch(fetchEyePartners())
    }, [])
    const handleEyeForm = (item) => {
        naviagte("/eye-detail", { state: item })
    }

    const handleOtherPartner = () => {
        const data = {
            type: "Eye",
            pack: PackData?.package_id
        }
        naviagte("/other-partner-form", { state: data })
    }
    const height = "70vh"
    return (
        <div className='home-review-banner'>
            <div className="home-app-header sticky-top">
                <div className="header-logo">
                    <img src={headerLogo} width="100%" alt="header-logo" />
                    <div className="user-name-text-repo">
                        <p style={{ fontSize: '16px', marginLeft: '10px' }}>Eye</p>
                    </div>
                </div>
            </div>

            {DentalLoader ? (
                <MediLoader height={height} />
            ) : (
                <>
                    <div className="banner">
                        <Swiper
                            spaceBetween={15}
                            slidesPerView={1}
                            loop={true}
                            centeredSlides={true}
                            autoplay={{ delay: 2000, disableOnInteraction: false }}
                            modules={[Autoplay]}
                        >
                            {DenatlData?.data?.banners?.map((item, index) => (
                                <SwiperSlide key={index} className="bannersliderimg">
                                    <div className="denatl-box">
                                        <img
                                            src={item?.image_url}
                                            width="100%"
                                            alt="app-banner-img"
                                        />
                                    </div>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </div>

                    <div class="partners-section">
                        <div className="part-repo-text-dot">
                            <div className="dot-line"></div>
                            <h3>Our Partners</h3>
                            <div className="dot-line"></div>
                        </div>
                        <div class="partners-logos">
                            {DenatlData?.data?.partners?.map((item) => (
                                <div class="partner-card-dental" onClick={() => handleEyeForm(item.partner_id)}>
                                    <img src={item?.icon} alt={item.name} />
                                    <p>{item?.partner_name}</p>
                                </div>
                            ))}
                        </div>

                        <div className="other-partner-repo-clover" onClick={handleOtherPartner}>
                           <img src={EyeOtherBanner} alt="" />
                        </div>
                    </div>
                </>
            )}




        </div>
    )
}

export default Eye