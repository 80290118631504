import React, { useEffect, useRef, useState } from 'react';
import { headerLogo } from '../../images';
import './EyeDental.css';
import { FiSearch } from 'react-icons/fi';
import { DentalServices1, DentalServices2, DentalServices3, DentalServices4, DoctorEyeImage } from '../Medicine/Images';
import { useDispatch, useSelector } from 'react-redux';
import { fetchDentalPartnersDetails } from '../../Redux/actions/GetApiCall';
import useLocationState from '../Medicine/hooks/useLocationState';
import { useNavigate } from 'react-router';
import MediLoader from '../Medicine/animation/MediLoader';

const DentalDetails = () => {
    const [expanded, setExpanded] = useState(false);
    const [searchTerm, setSearchTerm] = useState(''); // State to store search input
    const dispatch = useDispatch();
    const { data: PartnerData, loader: PatnerLoader } = useSelector(state => state.dental_partners_details);
    const [isOverflowing, setIsOverflowing] = useState(false);
    const textRef = useRef(null);
    const id = useLocationState();
    const naviagte = useNavigate();

    useEffect(() => {
        dispatch(fetchDentalPartnersDetails(id));
    }, [dispatch, id]);

    const toggleExpand = () => {
        setExpanded(!expanded);
    };

    // Check if the text content is overflowing
    useEffect(() => {
        if (textRef.current) {
            const isOverflow = textRef.current.scrollHeight > textRef.current.clientHeight;
            setIsOverflowing(isOverflow);
        }
    }, []);

    // Filter doctors based on search term
    const filteredDoctors = PartnerData?.data?.doctors?.filter((doctor) =>
        doctor?.doctor_name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const handleDoctorForm = (data) => {
        const RepoData = {
            data: data,
            partner_name: PartnerData?.data?.partner_name
        }
        naviagte("/dental-detail-form", { state: RepoData })
    }

    const height = "75vh"

    return (
        <div className='home-review-banner'>
            <div className="home-app-header sticky-top">
                <div className="header-logo">
                    <img src={headerLogo} width="100%" alt="header-logo" />
                    <div className="user-name-text-repo">
                        <p style={{ fontSize: '16px', marginLeft: '10px' }}>{PartnerData?.data?.partner_name}</p>
                    </div>
                </div>
            </div>

            {PatnerLoader ? (
                <MediLoader height={height} />
            ) : (
                <div className="details-eyedental-bankai">
                    <div className="partner-logo-bankai-repo">
                        <img src={PartnerData?.data?.icon} alt="" />
                    </div>

                    <div className="details-content-eyedental-bankai">
                        <div className="text-container">
                            <p
                                ref={textRef}
                                className={`text-content ${expanded ? 'expanded' : 'collapsed'}`}
                            >
                                {PartnerData?.data?.description}
                            </p>
                            {isOverflowing && (
                                <span className="view-toggle" onClick={toggleExpand}>
                                    {expanded ? 'View Less' : '... View More'}
                                </span>
                            )}
                        </div>

                        <div className="our-eyedentalservices-banaki">
                            <div className="our-bankai-services">
                                <div className="circle-bank"></div>
                                <span>Our Services</span>
                            </div>
                            <div className="contant-services-repo-tokyo">
                                <div className="repo-content-tepo-bank">
                                    <img src={DentalServices1} alt="" />
                                    <span>Braces Treatment</span>
                                </div>
                                <div className="repo-content-tepo-bank">
                                    <img src={DentalServices2} alt="" />
                                    <span>Teeth Whitening</span>
                                </div>
                                <div className="repo-content-tepo-bank">
                                    <img src={DentalServices3} alt="" />
                                    <span>Root Canal Treatment</span>
                                </div>
                                <div className="repo-content-tepo-bank">
                                    <img src={DentalServices4} alt="" />
                                    <span>Dental Implant</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="search-bar bar-banakia">
                        <input
                            type="text"
                            placeholder="Search by doctor name..."
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)} // Update search term
                        />
                        <FiSearch />
                    </div>
                    <div className="doctor-card-repo-bank-ai">
                        {filteredDoctors?.length > 0 ? (
                            filteredDoctors.map((item, index) => (
                                <div className="card-wrapper-bankai-rpeo" key={index} onClick={() => handleDoctorForm(item)}>
                                    <div className="image-repo-doc-banaki">
                                        <img src={DoctorEyeImage} alt="" />
                                    </div>
                                    <div className="content-repo-doc-ank-ai">
                                        <h6>{item?.doctor_name}</h6>
                                        <p>{item?.doctor_designation}</p>
                                        <p className='repo-text-span'>{item?.clinic_address}</p>
                                        <div className="repo-proicce-bankai">
                                            <div className="crop-pricerepo">
                                                <span>₹ {Math.round(item?.clinic_medibhai_price)}</span>
                                                <p>₹ {Math.round(item?.clinic_mrp)}</p>
                                            </div>
                                            <button>Book Now</button>
                                        </div>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <p className='cluto-repo-repo'>No doctors found</p>
                        )}
                    </div>
                </div>
            )}

        </div>
    );
};

export default DentalDetails;
