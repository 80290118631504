import React from 'react'
import '../../OrderLabTestPages/styles/labdetailscard.css'
import { NavLink, useNavigate } from 'react-router-dom';
import { teleconsultation_icon_order } from '../../../../Medicine/Images';

const DentalConsultationCard = ({ OrderData, Tabkey }) => {
    const navigate = useNavigate();
    const handleOrderDetails = (orderId) => {
        const data = {
            orderId: orderId,
            tabKey: Tabkey
        }
        navigate('/dental-order-details', { state: data })
    }

    return (
        <div className="order-cover-repo-wrap-box-con">
            {OrderData && OrderData?.data?.data?.length === 0 ? (
                <div class="empty-order-container">
                    <p class="empty-order-text">Your Dental order list is empty. Explore and book now!</p>
                    <NavLink to={'/dental'}>
                        <button class="empty-order-button">
                            <img src={teleconsultation_icon_order} alt="" />
                            Explore Dental</button>
                    </NavLink>
                </div>
            ) : (
                <>
                    {OrderData && OrderData?.data?.data?.map((item) => (
                        <div className="order-box-coverdetails tele-box-repo" onClick={() => handleOrderDetails(item?.id)} key={item?.id}>
                            <div className="order-date-details">
                                <p className='lab-book-text'>Booked on {item?.booked_on}</p>
                                <span
                                    style={{
                                        backgroundColor: item?.order_status_color_code,
                                    }}
                                >
                                    {item?.order_status}
                                </span>

                            </div>
                            <hr className='orderhr' />
                            <div className="orderDetails-product-box">
                                <div className="left-box-cover">
                                    <div className="details-box-repo-text lab-test-text-order">
                                        <p>Patient Name - {item?.patient_name}</p>
                                        <p>Appoinment Date - {item?.appointment_date}</p>
                                    </div>
                                </div>
                                <div className="right-box-cover-details">
                                    <button>View Details</button>
                                </div>
                            </div>
                        </div>
                    ))}
                </>
            )}
        </div>
    )
};

export default DentalConsultationCard