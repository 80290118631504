import React, { useEffect, useState } from 'react';
import { appOPDIcon, headerLogo } from '../../images';
import useLocationState from '../Medicine/hooks/useLocationState';
import { MdSchool } from 'react-icons/md';
import { FaBuilding, FaCalendarAlt } from 'react-icons/fa';
import './doctorslot.css';
import { IoPartlySunnySharp } from "react-icons/io5";
import { MdSunny } from "react-icons/md";
import { IoMdMoon } from "react-icons/io";
import { Tab, Tabs } from 'react-bootstrap';
import DatePicker from 'react-date-picker';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { doctorTimeSlot } from '../../Redux/actions/PostApiCall';
import LoaderAnime from '../Medicine/animation/prescription_loader';
import toast from 'react-hot-toast';
import ToastBox from '../Medicine/animation/ToastBox';

const TimeSlots = () => {
    const Data = useLocationState();
    console.log(Data)
    const [selectedSlot, setSelectedSlot] = useState(null);
    const DoctorTimeSlot = useSelector(state => state.doctor_time_slot);
    const [activeTab, setActiveTab] = useState("tab1");
    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(false);
    const dispatch = useDispatch();

    const formatDateUpdate = (date) => {
        if (!date) return "";

        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
    };


    const [selectedDate, setSelectedDate] = useState(null); // Default is null (no date selected)
    const [updateDate, setUpdateDate] = useState("");

    useEffect(() => {
        // Check if user has selected a date; otherwise, use Data?.selectedDateRepo
        const formattedDate = selectedDate
            ? formatDateUpdate(selectedDate)
            : Data?.selectedDateRepo;

        setUpdateDate(formattedDate);

        const DataRepo = {
            doctor_id: Data?.data?.doctor_id,
            hospital_id: Data?.hospitalId,
            date: formattedDate
        };

        dispatch(doctorTimeSlot(DataRepo));
        setActiveTab("tab1");
    }, [selectedDate, Data?.selectedDateRepo]);

    console.log(updateDate); // Logs the correct date


    const handleSlotSelection = (slot) => {
        const date = activeTab === "tab1" ? selectedDate : new Date(selectedDate.getTime() + 24 * 60 * 60 * 1000);
        const updatedDate = formatDateUpdate(date);
        console.log(updatedDate)
        setSelectedSlot({ slot, updatedDate });
    };

    // const handleTabChange = (tabKey) => {
    //     let formattedDate = selectedDate;

    //     if (tabKey === 'tab1') {
    //         // Stay with the current selected date
    //         formattedDate = selectedDate;
    //     }

    //     const DataRepo = {
    //         doctor_id: Data?.data?.doctor_id,
    //         hospital_id: Data?.hospitalId,
    //         date: formatDateUpdate(tabKey === 'tab1' ? selectedDate : nextDay) // Send `nextDay` for tab2 without modifying `selectedDate`
    //     };

    //     dispatch(doctorTimeSlot(DataRepo));
    //     setActiveTab(tabKey);
    //     setSelectedSlot(null); // Clear the slot selection
    // };


    const formatDate = (date) => date?.toLocaleDateString('en-US', { day: 'numeric', month: 'long' });
    // const nextDay = new Date(selectedDate);
    // nextDay.setDate(selectedDate.getDate() + 1);

    const handleDoctorOrder = () => {
        const orderData = {
            DoctorData: Data?.data,
            Online_time: selectedSlot?.slot,
            online_date: updateDate,
            doctor_id: Data?.data?.doctor_id,
            panelType: Data?.panelType,
            panelName: Data?.panelName,
            departmentType: Data?.departmentType,
            hospitalId: Data?.hospitalId
        };
        navigate("/doctor-form", { state: orderData });
    };

    useEffect(() => {
        if (DoctorTimeSlot?.status === "loading") {
            setShowModal(true);
        } else {
            setShowModal(false);
        }
    }, [DoctorTimeSlot]);

    const minDate = new Date(); // Current date
    const maxDate = new Date(); // 3 months from now
    maxDate.setMonth(maxDate.getMonth() + 3);

    return (
        <div className='home-review-banner'>
            {showModal && <LoaderAnime showModal={showModal} />}
            <div className="home-app-header sticky-top">
                <div className="header-logo">
                    <img src={headerLogo} width="100%" alt="header-logo" />
                    <div className="user-name-text-repo">
                        <p style={{ fontSize: '16px', marginLeft: '10px' }}>Doctor Details</p>
                    </div>
                </div>
            </div>

            <div className="doctor-data-repo">
                <div className="doctor-tepo-image">
                    <img src={appOPDIcon} alt="" />
                    <div className="dot"></div>
                </div>
                <div className="doctor-data-box-tepo">
                    <h5 style={{ marginBottom: '0px' }}>{Data?.data?.doctor_name}</h5>
                    <p style={{ fontSize: "11px", color: "#757575", marginBottom: '5px' }}>{Data?.data?.category || Data?.data?.departments?.[0]?.department_name}</p>
                    <p className="doctor-category"><MdSchool /> {Data?.data?.education}</p>
                    <p className="doctor-category"><FaBuilding /> {Data?.data?.hospital_details?.[0]?.hospital_address}</p>
                </div>
            </div>

            <div className="container">
                <div className="repo-cal-clover">
                    <h6>Choose Your Consultation Date</h6>
                    <span>Date: {updateDate}</span>
                    <FaCalendarAlt size={24} />
                    <DatePicker
                        selected={selectedDate}
                        onChange={(date) => setSelectedDate(date)}
                        dateFormat="dd MMMM yyyy"
                        minDate={minDate} // Current date
                        maxDate={maxDate} // Three months from now
                        showMonthYearDropdown // Allow changing months but not years
                        dropdownMode="select"
                        inline
                        className="datepicker-calendar"
                    />
                </div>

                {DoctorTimeSlot?.data?.grouped_times &&
                    Object.keys(DoctorTimeSlot.data.grouped_times).length > 0 ? (
                    (() => {
                        // Extract grouped_times
                        const groupedTimes = DoctorTimeSlot.data.grouped_times;

                        // Check if all periods have empty arrays
                        const allPeriodsEmpty = Object.values(groupedTimes).every(
                            (slotData) => slotData.length === 0
                        );

                        return allPeriodsEmpty ? (
                            // If all periods have empty arrays
                            <p className='repo-selcted-text-clover-epr'>
                                No slots available for the selected date.
                            </p>
                        ) : (
                            // If some periods have slots
                            Object.entries(groupedTimes).map(([period, slotData]) => {
                                return (
                                    <>
                                        {slotData.length > 0 ? (
                                            <div className="time-slot-section mt-3" key={period}>
                                                <div className="time-slot-header">
                                                    <h3>
                                                        {period === 'morning' && <IoPartlySunnySharp />}
                                                        {period === 'afternoon' && <MdSunny />}
                                                        {period === 'evening' && <IoMdMoon />}
                                                        {' '}
                                                        {period.charAt(0).toUpperCase() + period.slice(1)}
                                                    </h3>
                                                    <span
                                                        className="slot-count"
                                                        style={{
                                                            backgroundColor: slotData.filter(slot => slot?.available).length === 0 ? 'red' : '#dbf3e8',
                                                            color: slotData.filter(slot => slot?.available).length === 0 ? 'white' : '',
                                                        }}
                                                    >
                                                        {slotData.filter(slot => slot?.available).length} Slots
                                                    </span>
                                                </div>
                                                <div className="time-slots">
                                                    {slotData.map((slot, index) => (
                                                        <div
                                                            key={index}
                                                            className={`slot ${slot?.available ? 'available' : 'unavailable'} 
                                                ${selectedSlot?.slot === slot?.time && activeTab === "tab1" && slot?.available ? 'selected' : ''}`}
                                                            onClick={() => {
                                                                if (slot?.available) {
                                                                    handleSlotSelection(slot?.time);
                                                                } else {
                                                                    toast.error('Sorry, this slot is already booked.');
                                                                }
                                                            }}
                                                        >
                                                            {slot?.time}
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        ) : null}
                                    </>
                                );
                            })
                        );
                    })()
                ) : (
                    <></>
                )}



                {/* <Tabs
                    activeKey={activeTab}
                    onSelect={handleTabChange}
                    className="mb-1 slot-clover-tabs-repo">
                    <Tab
                        eventKey="tab1"
                        title={
                            <>
                                <div>{formatDate(selectedDate)}</div>
                                
                            </>
                        }
                        disabled={activeTab === 'tab1'}
                    >
                        

                    </Tab>

                    <Tab
                        eventKey="tab2"
                        title={
                            <>
                                <div>{formatDate(nextDay)}</div>
                            </>
                        }
                        disabled={activeTab === 'tab2'}
                    >
                        {DoctorTimeSlot?.data?.grouped_times && Object.keys(DoctorTimeSlot.data.grouped_times).length > 0 ? (
                            Object.entries(DoctorTimeSlot.data.grouped_times).map(([period, slotData]) => {
                                return (
                                    <>
                                        {slotData.length > 0 && (
                                            <div className="time-slot-section mt-3" key={period}>
                                                <div className="time-slot-header">
                                                    <h3>
                                                        {period === 'morning' && <IoPartlySunnySharp />}
                                                        {period === 'afternoon' && <MdSunny />}
                                                        {period === 'evening' && <IoMdMoon />}
                                                        {' '}
                                                        {period.charAt(0).toUpperCase() + period.slice(1)}
                                                    </h3>
                                                    <span
                                                        className="slot-count"
                                                        style={{
                                                            backgroundColor: slotData.filter(slot => slot?.available).length === 0 ? 'red' : '#dbf3e8',
                                                            color: slotData.filter(slot => slot?.available).length === 0 ? 'white' : '',
                                                        }}
                                                    >
                                                        {slotData.filter(slot => slot?.available).length} Slots
                                                    </span>
                                                </div>
                                                <div className="time-slots">
                                                    {slotData.map((slot, index) => (
                                                        <div
                                                            key={index}
                                                            className={`slot ${slot?.available ? 'available' : 'unavailable'} 
                                                            ${selectedSlot?.slot === slot?.time && activeTab === "tab2" && slot?.available ? 'selected' : ''}`}
                                                            onClick={() => {
                                                                if (slot?.available) {
                                                                    handleSlotSelection(slot?.time);
                                                                } else {
                                                                    toast.error('Sorry, this slot is already booked.');
                                                                }
                                                            }}
                                                        >
                                                            {slot?.time}
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        )}
                                    </>
                                );
                            })
                        ) : (
                            <p className='repo-selcted-text-clover-epr'>No slots available for the selected date.</p>
                        )}
                    </Tab>
                </Tabs> */}
            </div>

            {
                selectedSlot && (
                    <div className="doctor-buttons-repo-detail sticky-bottom repo-buttons-tepo">
                        <button className="online-btn" onClick={handleDoctorOrder}>
                            {Data?.panelType === 1 ? "Online Consultation" : "In-person Consultation"}
                        </button>
                    </div>
                )
            }
            <ToastBox />
        </div>
    );
};

export default TimeSlots;
