import React, { useEffect, useState } from 'react'
import { headerLogo } from '../../images'
import { MY_ACCOUNT_API, PACKAGES_API, SAVE_PACKAGES_API } from '../../Constants';
import { genderList, relationshipList, relationshipOPDList } from '../../data';
import { FaRegCircleXmark } from 'react-icons/fa6';
import { PiUploadBold } from "react-icons/pi";
import WaitLoader from '../../Animation/WaitLoader';
import { useForm } from 'react-hook-form';
import SuccessFull from '../../Animation/SuccessFull';
import { useNavigate } from 'react-router';
import OpdLoader from '../../Animation/OpdLoader';
import { fetchPackageList } from '../../Redux/actions/GetApiCall';
import { useDispatch, useSelector } from 'react-redux';
import { validateNumberInput } from '../../utils/AllFunction';
import { DentalBanner, DentalBanner2, opdBanner } from '../Medicine/Images';
import { doctorTimeSlot, doctorTimeSlotForm, opd_book } from '../../Redux/actions/PostApiCall';
import LoaderAnime from "../Medicine/animation/prescription_loader";
import Medicine_Success from '../Medicine/animation/Medicine_Success';
import useLocationState from '../Medicine/hooks/useLocationState';
import { format } from 'date-fns';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';
import { formatDate } from 'date-fns';



const DemoOPD = () => {
    const [successmodal, setSuccessModal] = useState(false);
    const [modalShow, setModalShow] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const DataId = useLocationState();
    console.log(DataId)

    // const [selectedPackage, setSelectedPackage] = useState('');
    const package_list = useSelector((state) => state?.package_list);
    const opd_response = useSelector((state) => state?.opd_book_response);
    console.log(package_list)
    useEffect(() => {
        dispatch(fetchPackageList())
    }, [dispatch])

    const { register, handleSubmit, formState: { errors } } = useForm(
    );

    // const handleSelectChange = (event) => {
    //     const selectedValue = event.target.value;
    //     console.log(selectedValue)
    //     // const selectedPackageData = packagesData.find(data => data.id.toString() === selectedValue);
    //     setSelectedPackage(selectedValue);
    // };

    const onSubmit = async (data) => {
        const formattedDate = selectedDate ? format(selectedDate, 'dd-MM-yyyy') : null;
        setModalShow(true)
        const onDataSubmit = {
            user_name: package_list?.data?.profile_details?.email_id,
            user_mobile_number: package_list?.data?.profile_details?.mobile_number,
            user_email: package_list?.data?.profile_details?.email_id,
            // user_id: package_list?.data?.profile_details?.id,
            patient_name: data?.patient_name,
            package_id: DataId?.package_id,
            patient_age: data?.patient_age,
            patient_age_type: data?.age_type,
            patient_gender: data?.gender,
            patient_relation: data?.relation,
            pincode: data?.pincode,
            city: data?.city,
            hospital_name: data?.hospital_name,
            doctor_name: data?.doctor_name,
            date: formattedDate,
            time: data?.time
        }
        console.log(onDataSubmit)
        await dispatch(opd_book(onDataSubmit))
    }

    useEffect(() => {
        if (opd_response?.status === "success") {
            setModalShow(false)
            setSuccessModal(true)

            setTimeout(() => {
                setSuccessModal(false)
                navigate("/home")
            }, 2500);
        }
    }, [opd_response])

    const [loading, setLoading] = useState(false);
    const DoctorTimeSlot = useSelector(state => state.doctor_time_slot_form);
    const [selectedDate, setSelectedDate] = useState('');

    const handleDateChange = (date) => {
        const formData = new FormData();
        setSelectedDate(date);
        const formattedDate = format(date, 'yyyy-MM-dd');

        if (formattedDate) {
            const RepoData = {
                date: formattedDate
            }
            dispatch(doctorTimeSlotForm(RepoData))
                .finally(() => setLoading(false)); // End loading after the API call completes
        }
    };

    const minDate = new Date(); // Current date
    const maxDate = new Date(); // 3 months from now
    maxDate.setMonth(maxDate.getMonth() + 3);


    return (
        <div className="home-review-banner">
            {modalShow && <LoaderAnime showModal={modalShow} />}
            <div className="home-app-header d-flex justify-content-start align-items-center">

                <div className="header-logo mx-3">
                    <img
                        src={headerLogo}
                        className="header-medibhai-logo"
                        width="100%"
                        alt="header-logo"
                    />
                    <p className='opd-text'>OPD</p>
                </div>
            </div>
            <div className="opd-wrapper-cover-data mt-3">
                <div className="container">
                    <div className="image-form-opd">
                        <img src={opdBanner} alt="" />
                    </div>

                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="heading-data-form">
                            {/* <p>Get in person consultation reimbursement up to 5000</p> */}
                        </div>
                        {/* {(DataId === null || DataId === "") && (
                            <div className="ope-select-data-box mb-2">
                                <p>Please Select the Package</p>
                                <select
                                    {...register("selectedPackage", { required: "Please select a package" })}
                                    defaultValue=""
                                >
                                    <option value="" disabled>Please select package</option>
                                    {package_list?.data?.active_package?.map((data, index) => (
                                        <option key={index} value={data?.id}>{data?.name}</option>
                                    ))}
                                </select>
                                {errors.selectedPackage && (
                                    <p className="error">{errors.selectedPackage.message}</p>
                                )}
                            </div>
                        )} */}
                        <div className="form-data-repo-box">
                            <input type="text" value={package_list?.data?.profile_details?.mobile_number} disabled />
                            <input type="text" value={package_list?.data?.profile_details?.email_id} disabled />
                        </div>
                        <div className="form-page-data">
                            <div className="page-heading-text">
                                <p>Consultation Details</p>
                            </div>

                            <div className="form-page">
                                <input type="text" placeholder='Enter Patient Name' {...register('patient_name', { required: true })} />
                                {errors.patient_name && <span className='error-text-mssg'>This field is Required</span>}
                                <div className="option-data-page">
                                    <div className="gender-select-page">
                                        <select
                                            name="gender"
                                            {...register('gender', { required: true })}>
                                            <option value="" selected disabled>Select Gender</option>
                                            {genderList.map((data, index) => (
                                                <option key={index} value={data?.text}>{data?.text}</option>

                                            ))}
                                        </select>
                                        {errors.gender && <span className='error-text-mssg gen-text'>Gender is required</span>}
                                    </div>
                                    <div className="gender-select-page">
                                        <select
                                            name="relation"
                                            {...register('relation', { required: true })}>
                                            <option value="">Select Relation</option>
                                            {relationshipOPDList.map((data, index) => (
                                                <option key={index} value={data?.text}>{data?.text}</option>
                                            ))}
                                        </select>
                                        {errors.relation && <span className='error-text-mssg gen-text'>Relation is required</span>}
                                    </div>
                                </div>
                                <div className="option-data-page mb-0">
                                    <div className="gender-select-page">
                                        <input type="text" placeholder='Enter Patient Age' onInput={validateNumberInput} maxLength={3} {...register('patient_age', { required: true })} />
                                        {errors.patient_age && <span className='error-text-mssg'>age is required</span>}
                                    </div>
                                    <div className="gender-select-page">
                                        <select
                                            {...register("age_type", {
                                                required: "Age type is required",
                                            })}
                                            defaultValue="" // sets default selected value
                                        >
                                            <option value="" disabled>
                                                Select Age Type
                                            </option>
                                            <option value="days">Days</option>
                                            <option value="months">Month</option>
                                            <option value="years">Years</option>
                                        </select>

                                        <span className="error-text-mssg gen-text">
                                            {errors.age_type && errors.age_type.message}
                                        </span>
                                    </div>
                                </div>
                                <div className="option-data-page mb-0">
                                    <div className="gender-select-page">
                                        <DatePicker
                                            selected={selectedDate}
                                            onChange={handleDateChange}
                                            className="bankai-date"
                                            id="cancelDate"
                                            dateFormat="dd/MM/yyyy"
                                            placeholderText="Select Date"
                                            minDate={minDate}
                                            maxDate={maxDate}
                                            autoComplete="off"
                                            auto
                                            required
                                        />
                                    </div>
                                    <div className="gender-select-page">
                                        <select
                                            id="cancelReason"
                                            defaultValue=""
                                            {...register("time", {
                                                required: "time is required",
                                            })}
                                            disabled={loading || !DoctorTimeSlot?.data?.time_slot?.length}
                                            style={{ padding: '0px', height: '40px' }}
                                        >
                                            <option value="" disabled>
                                                Select Time
                                            </option>
                                            {DoctorTimeSlot?.data?.time_slot?.map((time) => (
                                                <option key={time} value={time}>
                                                    {time}
                                                </option>
                                            ))}
                                        </select>
                                        <span className="error-text-mssg gen-text">
                                            {errors.time && errors.time.message}
                                        </span>

                                    </div>

                                </div>
                                <input type="text" placeholder='Enter Hospital Name' {...register('hospital_name', { required: true })} />
                                {errors.hospital_name && <span className='error-text-mssg'>hospital name is required</span>}
                                <input type="text" placeholder='Enter Doctor Name' {...register('doctor_name', { required: true })} />
                                {errors.doctor_name && <span className='error-text-mssg'>doctor name is required</span>}
                                <input
                                    placeholder="Enter Pincode"
                                    {...register("pincode", {
                                        required: "Pincode is required",
                                        pattern: {
                                            value: /^[0-9]{6}$/,
                                            message: "Invalid pincode"
                                        },
                                    })}
                                    maxLength={6}
                                    onInput={validateNumberInput}
                                />
                                {errors.pincode && <span className="error-text-mssg">{errors.pincode.message}</span>}
                                <input
                                    type="text"
                                    id="city"
                                    placeholder='Enter City'
                                    {...register("city", {
                                        required: "city is required",
                                    })}
                                />
                                {errors.city && <span className="error-text-mssg">{errors.city.message}</span>}
                            </div>
                        </div>

                        <div className="submit-btn-wrap">
                            <button>Submit</button>
                        </div>
                    </form>
                </div>
            </div>
            {opd_response && <Medicine_Success showModal={successmodal} message={opd_response?.message} status={opd_response?.success} />}
        </div>
    )
}

export default DemoOPD;