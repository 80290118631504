import React, { useEffect, useState } from 'react';
import Medicine_Header from '../Components/Medicine_Header';
import Cart_Card from '../Components/Cart_Card';
import { useDispatch, useSelector } from 'react-redux';
import Medicine_footer from '../Components/Medicine_footer';
import { medi_wallet_image, Need_Help_Image, package_banner_image, savings_image } from '../Images';
import '../Medicine_Style/order-style.css';
import useLocationState from '../hooks/useLocationState';
import { fetchMedicineCartList, fetchMedicineOrderList, fetchPackageList } from '../../../Redux/actions/GetApiCall';
import { appDeliverLocation } from '../../../images';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { captureOrderMedicine, orderMedicine } from '../../../Redux/actions/PostApiCall';
import useRazorpay from 'react-razorpay';
import LoaderAnime from "../animation/prescription_loader";
import Medicine_Success from '../animation/Medicine_Success';
import { set } from 'lodash';
import toast from 'react-hot-toast';
import Need_Help from '../Components/Need_Help';
import SharModal from '../Components/SharModal';
import CaptureLoader from '../animation/CaptureLoader';
import WalletLoader from '../animation/WalletLoader';

const Medicine_order = ({ tabkey, setCartData, setWalletId, setShowModalWallet, showModalWallet, setSelectedPackage, walletId, selectedPackage, setShowModal, showModal, setShowModalCapture, showModalCapture }) => {
    // const Title = "Placed Order";
    const [Razorpay] = useRazorpay();
    const dispatch = useDispatch();
    const naviagte = useNavigate();
    const [cartListData, setCartListData] = useState([]);
    // const [cartUpdatedData, setCartUpdatedData] = useState([]);
    const { data: MedicineData, loader: cartLoader } = useSelector(state => state.medicine_cart_list);
    const { data: MedicineOrderData } = useSelector(state => state.medicine_order_list);
    const orderedData = useSelector(state => state.order_medicine.data);
    const orderResponse = useSelector(state => state.order_medicine);
    const packagesData = useSelector(state => state.package_list);
    const [selectedData, setSelectedData] = useState('');
    const [isChecked, setIsChecked] = useState(false);
    const [successModal, setSuccessModal] = useState(false);
    const AppToken = localStorage.getItem("APP_TOKEN");
    const AppSimulated = localStorage.getItem("is_simulated");
    // const [paymentClose, setPaymentClose] = useState(false);
    const [showShare, setShowShare] = useState(false);
    const handleShareShow = () => setShowShare(true);
    const location = useLocation();

    // Use URLSearchParams to get the 'auto' parameter
    const queryParams = new URLSearchParams(location.search);
    const autoParam = queryParams.get('auto');

    useEffect(() => {
        if (orderResponse?.error !== null && orderResponse?.status === "failure") {
            setShowModal(false);
            setShowModalCapture(false)
            setShowModalWallet(false)
            toast.error(orderResponse?.error)
        }
    }, [orderResponse])

    useEffect(() => {
        const storedToken = localStorage.getItem("APP_TOKEN");

        if (autoParam && storedToken !== autoParam) {
            localStorage.setItem("APP_TOKEN", autoParam);
            window.location.reload();
        }
    }, [autoParam, location.search]);




    const cartUpdatedData = useSelector((state) => state.medicine_cart_list.data);

    useEffect(() => {
        dispatch(fetchMedicineOrderList())
    }, [dispatch])

    useEffect(() => {
        if (cartListData) {
            setCartData(cartListData)
        }
    }, [cartListData, setCartData])

    // useEffect(() => {
    //     if (cartListData) {
    //         setCartUpdatedData(cartListData);
    //     } else if (MedicineData) {
    //         setCartUpdatedData(MedicineData);
    //     }
    // }, [MedicineData, cartListData]);

    const handleSelectPackage = (e) => {
        const selectedValue = e.target.value;
        if (selectedValue) {
            try {
                const selectedItem = JSON.parse(selectedValue);
                setSelectedPackage(selectedItem?.id);
                setSelectedData(selectedItem);
                setIsChecked(false);
                setWalletId("");
            } catch (error) {
                console.error("Invalid JSON input:", selectedValue);
            }
        } else {
            setSelectedPackage("");
            setSelectedData("");
        }
    }


    const handleWalletChange = (e) => {
        const isCheckedNow = e.target.checked;
        setIsChecked(isCheckedNow);
        if (isCheckedNow) {
            setWalletId(selectedData?.wallet_id);
        } else {
            setWalletId("");
        }
    };

    const DataId = {
        medicine_order_id: orderedData?.medicine_order_id,
        onemg_order_id: orderedData?.onemg_order_id
    }

    useEffect(() => {
        if (orderedData?.success === true && orderedData?.out_of_stock?.length === 0 && orderedData?.is_payable === 0) {
            setSuccessModal(true);
            setTimeout(() => {
                setSuccessModal(false)
                naviagte('/thank-you', { state: DataId });
            }, 3000);
        }
        else if (orderedData?.success === false && orderedData?.out_of_stock?.length === 0) {
            setSuccessModal(true);
            setTimeout(() => {
                setSuccessModal(false)
                naviagte('/buyMedicines');
            }, 3000);
        }
        else if (orderedData?.success === true && orderedData?.out_of_stock?.length > 0) {
            toast.error(orderedData?.message);
            setTimeout(() => {
                naviagte('/cart');
            }, 2000);
        }
        else {
            setSuccessModal(false)
        }
    }, [orderedData, naviagte]);

    useEffect(() => {
        if (orderedData?.success === true && orderedData?.is_payable === 1 && orderedData?.out_of_stock?.length === 0 && orderResponse?.status === "success") {
            const optionsData = orderedData?.razorpay_details;

            const options = {
                key: optionsData?.key,
                amount: optionsData?.amount,
                currency: optionsData?.currency,
                name: optionsData?.name,
                description: optionsData?.description,
                order_id: optionsData?.razorpay_order_id,
                handler: async (response) => {
                    console.log("Payment successful", response);
                    if (response) {
                        const data = {
                            medicine_order_id: MedicineOrderData?.data?.medicine_order_id,
                            transaction_id: response?.razorpay_payment_id
                        }
                        setShowModal(true)
                        setShowModalCapture(false)
                        setShowModalWallet(false)
                        try {
                            const cartUpdateData = await fetch(`${process.env.REACT_APP_NEW_APP_API_URL}/medicine/capture-payment-details`, {
                                method: "POST",
                                headers: {
                                    "Content-Type": "application/json",
                                    Authorization: AppToken,
                                },
                                body: JSON.stringify(data),
                            });

                            console.log(cartUpdateData)

                            const CartpaymentData = await cartUpdateData.json();
                            console.log(CartpaymentData)
                            if (CartpaymentData.success === true) {
                                naviagte('/thank-you', { state: DataId });
                                // window.location.reload();
                            }
                            else if (CartpaymentData.success === false) {
                                toast.error(CartpaymentData.message);
                                setTimeout(() => {
                                    naviagte('/thank-you', { state: DataId });
                                }, 1500);
                                // window.location.reload();
                            }
                            else if (cartUpdateData.ok === false) {
                                naviagte('/thank-you', { state: DataId });
                                // window.location.reload();
                            }
                        } catch (error) {
                            console.log(error);
                        } finally {
                            setShowModal(false)
                            setShowModalCapture(false)
                            setShowModalWallet(false)
                        }
                        // await dispatch(captureOrderMedicine(data))
                    }
                },
                prefill: {
                    name: optionsData?.prefill?.name,
                    email: optionsData?.prefill?.email,
                    contact: optionsData?.prefill?.contact,
                },
                theme: {
                    color: "#3399cc",
                },
                modal: {
                    ondismiss: function () {
                        naviagte('/thank-you', { state: DataId });
                    }
                }

                // callback_url:optionsData?.callback_url
            };

            const rzp1 = new Razorpay(options);
            rzp1.open();
        }
    }, [orderedData]);


    useEffect(() => {
        if (MedicineData?.available_stock?.length === 0 && tabkey === "medicines") {
            naviagte('/buyMedicines')
        }
    }, [MedicineData])


    const handleAddressChange = () => {
        naviagte('/address-list', { state: tabkey })
    }

    useEffect(() => {
        // If `selectedPackage` is either empty or has a value, or when `walletId` changes
        if (selectedPackage !== undefined || walletId !== "") {
            dispatch(fetchMedicineCartList(selectedPackage, walletId));
        }
    }, [selectedPackage, walletId, dispatch]);

    return (
        <>
            {/* <Medicine_Header Title={Title} cartData={cartListData} /> */}

            {showModal && <LoaderAnime showModal={showModal} />}

            {showModalCapture && <CaptureLoader showModal={showModalCapture} />}

            {showModalWallet && <WalletLoader showModal={showModalWallet} />}

            {showShare && <SharModal setShowShare={setShowShare} showShare={showShare} Data={MedicineOrderData} />}

            <Cart_Card setCartListData={setCartListData} MedicineData={MedicineData} cartListData={cartListData} cartLoader={cartLoader} selectedPackage={selectedPackage} walletId={walletId} />

            {AppSimulated !== null && AppSimulated === "1" && (
                <div className="share-box" onClick={handleShareShow} >
                    <div className="content-share-repo">
                        <h6>Share Your Order</h6>
                        <p>Let employee know about this order!</p>
                    </div>
                    <button aria-label="Share Your Order">Click here</button>
                </div>
            )}


            {packagesData?.data?.active_package?.length > 0 && (
                <div className="packages-cover-wrapper">
                    <div className="options-wrapper">
                        <select value={selectedData ? JSON.stringify(selectedData) : ''} onChange={handleSelectPackage}>
                            <option value="">Select Package</option> {/* Default selected and disabled */}
                            {packagesData?.data?.active_package?.map((item) => (
                                <option key={item.id} value={JSON.stringify(item)}>
                                    {item.name}
                                </option>
                            ))}
                        </select>

                    </div>
                </div>
            )}

            {selectedData !== "" && selectedData?.wallet_id !== null && (
                <>
                    <div className="wallet-repo-wrapper">
                        <div className="check-data-wrapper">
                            <div className="checkbox-wrapper">
                                <input
                                    id="terms-checkbox-37"
                                    name="checkbox"
                                    type="checkbox"
                                    checked={isChecked} // Bind to the checkbox state
                                    onChange={handleWalletChange} // Handle change event directly
                                />
                                <label className="terms-label" htmlFor="terms-checkbox-37">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 200 200" className="checkbox-svg">
                                        <mask fill="white" id="path-1-inside-1_476_5-37">
                                            <rect height="200" width="200"></rect>
                                        </mask>
                                        <rect mask="url(#path-1-inside-1_476_5-37)" strokeWidth="40" className="checkbox-box" height="200" width="200"></rect>
                                        <path strokeWidth="15" d="M52 111.018L76.9867 136L149 64" className="checkbox-tick"></path>
                                    </svg>
                                </label>
                            </div>
                            <div className="wrapper-text-content-box">
                                <p>Medibhai Wallet</p>
                                <span>Remaining Balance : ₹ {selectedData?.wallet_details?.medicine?.remaining}</span>
                            </div>
                        </div>
                        <div className="used-data-wrapper">
                            <img src={medi_wallet_image} alt="" />
                        </div>

                    </div>
                    {isChecked && cartUpdatedData?.payable_amount > 0 && cartLoader === false &&(
                        <div className="wallet-heading-insu">
                            <p>
                                Your wallet balance is insufficient ₹{cartUpdatedData?.payable_amount}  payment is required online
                            </p>
                        </div>
                    )}
                </>
            )}

            <div className="payment-repo-cover-box">
                <div className="payment-text-repo">
                    <p>Payment Summary</p>
                </div>

                <div className="payment-summary-box-repo">
                    {cartUpdatedData?.vas_charges?.details?.map((item, index) => (
                        <div className="price-payment-sum-repo" key={index}>
                            <p>{item.display_text}</p>
                            <p>
                                {item?.amount === "free" ? `₹ 0` : `₹ ${item?.amount?.toLocaleString('en-IN')}`}
                            </p>
                        </div>
                    ))}
                    {cartUpdatedData?.wallet_deducted?.map((item, index) => (
                        <div className="price-payment-sum-repo" key={index}>
                            <p>MB Wallet</p>
                            <p>- ₹ {item.amount?.toLocaleString('en-IN')}</p>
                        </div>
                    ))}
                </div>
                <div className="fin-text-repo">
                    <div className="price-payment-sum-repo total-pay-text">
                        <p>Total Payable</p>
                        <p>₹ {cartUpdatedData?.payable_amount?.toLocaleString('en-IN')}</p>
                    </div>
                    <div className="price-payment-sum-repo total-save-text">
                        <p><img src={savings_image}></img>
                            Total Savings</p>
                        <p>₹ {cartUpdatedData?.total_saving?.toLocaleString('en-IN')}</p>
                    </div>
                </div>
            </div>

            <Need_Help type="medicine" />

            <div className="address-wrapper-box-order">
                <div className="address-content-box-save">
                    <img
                        src={appDeliverLocation}
                        width="100%"
                        alt="location"
                    />

                    <div className="address-content-repo-text">
                        <p>Deliver to {MedicineOrderData?.data?.address?.address_type} <br /> {MedicineOrderData?.data?.address?.address},{MedicineOrderData?.data?.address?.landmark},<br />{MedicineOrderData?.data?.address?.city},{MedicineOrderData?.data?.address?.state}-{MedicineOrderData?.data?.address?.pincode}</p>
                    </div>
                </div>

                <div className="chnage-address-btn">
                    <button onClick={handleAddressChange}>Change</button>
                </div>
            </div>
            {/* {orderedData && <Medicine_Success showModal={successModal} message={paymentClose ? "Payment Cancelled" : orderedData?.message} status={paymentClose ? false : orderedData?.success} />} */}
        </>
    )
}

export default Medicine_order;