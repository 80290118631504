import React from 'react';
import './RecentHospitalCard.css';
import { hospitalIcon } from '../Medicine/Images';
import { useNavigate } from 'react-router';
import { HospitalData } from '../../data';

const RecentHospitalCard = ({ item, index }) => {
    const navigate = useNavigate();
    const handleHospitalDetails = (item) => {
        navigate('/hospital-details', { state: item })
    }
    return (
        <div className="wrapper-clover-recent-boc" onClick={() => handleHospitalDetails(item)}>
            <div className="hospital-card-repoo" style={{ flexDirection: 'row', justifyContent: 'space-between'}} key={index}>
                <div className="hospital-data-wrap" style={{ textAlign: 'start' }}>
                    <p>Name: <span>{item?.name}</span></p>
                    <p style={{ color: 'GrayText' }}>City: <span>{item?.city}</span></p>
                    <p style={{ color: 'GrayText' }}>Speciality: <span>Multi-speciality</span></p>
                    <p style={{ color: 'GrayText' }}>
                        Doctors: <span>12</span>
                    </p>
                </div>
                <img src={hospitalIcon} alt="hospital-image" style={{ width: '20%' }} />
            </div>
            <div className="button-visit-repo">
                <button>
                    <p>View Hospital</p>
                </button>
            </div>
        </div>
    );
};

export default RecentHospitalCard;
