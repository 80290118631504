import React from 'react'
import WellnessHeader from './WellnessHeader'
import FormWellnessPages from './FormWellnessPages'
import { appNutritionFormImg } from '../../images'

const Nutrition = () => {
  return (
    <>
      <div className="home-review-banner">

        <WellnessHeader data={"Nutrition"} />
        <FormWellnessPages data="Nutrition" ImgData={appNutritionFormImg} bgColor="#FFF1CF 0% 0% no-repeat padding-box" btnColor="#FFC32F 0% 0% no-repeat padding-box" borderColor="1px solid #FFC32F" />
      </div>
    </>
  )
}

export default Nutrition